<!-- Inner Banner -->
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Nos Projets</h3>
          <!--  <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Projets</li>
            </ul> -->
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Area -->
<div class="project-area pt-100 pb-70">
    <div class="container">
        <div class="project-title-two">
            <div class="section-title">
                <span>Nos projets</span>
                <h2> S'activer dans le monde depuis la ville de Diourbel</h2>
                <p>Sidio Group privilégie des investissements dans les domaines clé pouvant garantir une forte croissance économique et de création d’emplois dans la région du baol et au delà.</p>
            </div>
        </div>

        <div class="tab project-tab text-center">
            <ul class="tabs">
                <li><a href="#" routerLink="/projects-details-agro">Agro-alimentaire </a></li>
                <li><a href="#" routerLink="/projects-details-sante">Santé & Bien-être</a></li>
                <li><a href="#"  routerLink="/projects-details-machine">Machinerie</a></li>
                <li><a href="#" routerLink="/projects-details-prod">Productions industrielles diverses</a></li>
                <li><a href="#" routerLink="/projects-details-services">Services</a></li>
                <li><a href="#"  routerLink="/projects-details-immo">Immobilier</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-agro"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details-agro">Agro-alimentaire</a></h3>
                                        <div class="content">
                                            <p>Pour en savoir plus sur les  activités dans le domaine de l'agroalimentaire ...Pour en savoir plus sur les  activités dans le domaine de l'agroalimentaire ...</p>
                                            <a routerLink="/projects-details-agro" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-sante"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details-sante">Santé & Bien-être</a></h3>
                                        <div class="content">
                                          <p>En savoir plus sur les  activités du groupe dans le domaine de la santé et du bien-être ... </p>
                                            <a routerLink="/projects-details-sante" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-machine"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details-machine">Machinerie</a></h3>
                                        <div class="content">
                                            <p>En savoir plus sur les activités du groupe dans le domaine de la machinerie ...</p>
                                            <a routerLink="/projects-details-machine" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details-prod"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details-prod">Productions industrielles diverses</a></h3>
                                        <div class="content">
                                           <p>En savoir plus sur nos activités dans le domaine de la  Productions industrielles diverses  ...</p>
                                            <a routerLink="/projects-details-prod" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-services"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details-services">Services</a></h3>
                                        <div class="content">
                                          <p>Pour en savoir plus sur les activités du groupe dans le domaine des services ....Pour en savoir plus sur les activités du groupe dans le domaine des services ...Pour en savoir plus sur les activités du groupe dans le domaine des services ....Pour en savoir plus sur les activités du groupe dans le domaine des services ...</p>
                                            <a routerLink="/projects-details-services" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-immo"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details-immo">Immobilier    </a></h3>
                                        <div class="content">
                                            <p>Pour en savoir plus sur les activités du groupe dans le domaine de l'immobilier ...Pour en savoir plus sur les activités du groupe dans le domaine de l'immobilier ...</p>
                                            <a routerLink="/projects-details-immo" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--
                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 Project Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>SIDIO: un groupe qui s'active dans le monde depuis Diourbel</h2>
             </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->


<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Soumettre</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->
