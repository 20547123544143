<!-- Inner Banner -->
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contactez-nous</h3>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxs-phone'></i>
                    <div class="content">
                        <h3>Contacts téléphoniques</h3>
                        <span><a href="tel:+339715292">+33 971 52 92</a></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bx-map'></i>
                    <div class="content">
                        <h3>Adresse</h3>
                        <p>Boulevard Ely Manel Fall BP 565.</p>
                        <span>Diourbel - Sénégal</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:infos@sidio-group.com">infos@sidio-group.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>SIDIO: un groupe qui s'active dans le monde depuis Diourbel</h2>
             </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Soumettre</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<div class="map-area">
    <iframe src="https://maps.google.com/maps?q=boulevard%20ely%20manel%20fall%20diourbel&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>

</div>
<!-- Map Area End -->
