<!-- Inner Banner -->
<div class="inner-banner inner-bg18">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Projets</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Immobilier</li>d
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Details Area -->
<div class="project-details-area pt-100 pb-70">
    <div class="container">
        <div class="project-article">
            <div class="blog-article-img">
               <!-- <img src="assets/img/project/project-details1.jpg" alt="Images" align=left>-->
            </div>
<div class="project-content">
                <h2>PPP- PROJET DE REHABILITATION DU MARCHE KADD GUI </h2>
<div class="paragraph-right">
                <p>Projet de réhabilitation du marché Kadd de Keur Cheikh Casser le marché existant -Construction d’un nouveau marché moderne avec 86 cantines au RDC et 86 au 1er étage - Commercialisation -Coût estimé à
100.000.000 XOF pour RDC
                </p>
        </div>
            </div>
            <div class="project-content">
                <h2>Habitat Social </h2>
<div class="paragraph-left">
                <p>Dans le cadre du projet phare « Programme d’Accélération de l’Offre en Habitat Social » dont le but est d’apporter une réponse durable à l'accroissement de la population urbaine de notre pays, à l'étalement des centres urbains et à l'aspiration légitime des citoyens à de meilleures conditions de vie, le groupe SIDIO s'est mis à contribution en tant que promoteur engagé dans tout projet à impact social
                </p>
                <img src="assets/img/project/project-imgimmo.jpg" alt="Images">
</div>
            </div>
            </div>
</div>

<!-- Project Details Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>SIDIO: un groupe qui s'active dans le monde depuis Diourbel</h2>
             </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->


<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Soumettre</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->
