<head>
<meta name="viewport" content="width=device-width, initial-scale=1">

</head>
<!-- Top Header  -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                          <!--  <li>
                                <div class="head-icon">
                                    <i class='bx bxs-location-plus'></i>
                                </div>
                                <a href="#" target="_blank">Boulevard Ely Manel Fall, Diourbel</a>
</li>-->
<li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="infos@sidio-group.com">infos@sidio-group.com</a>
                            </li>
  <li>
<div class="head-icon">
                            <i class='bx bx-phone'></i>
</div>
                            <a href="tel:+221 33 971 52 92">+221339715292</a>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                          <!--  <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li> -->
                        </ul>
                    </div>

                    <div class="language">
                        <ul>
                            <li>
                               <a href="javascript:void(0)"><i class='bx bx-world language-icon'></i> Language <i class='bx bx-chevron-down'></i></a>
                                <ul>
                                    <li><a href="#">FR</a></li>
                                    <li><a href="#">EN</a></li>
                                    <li><a href="#">DE</a></li>
                               </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->
<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                                <li class="nav-item"><a routerLink="/" class="nav-link">Home</a></li>

                                        <li class="nav-item"><a routerLink="/projects" class="nav-link" >Nos projets</a></li>


                                        <li class="nav-item"><a routerLink="/faq" class="nav-link" >Les filiales</a></li>

                                <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Galerie </a></li>


                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                       <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li> -->

                    </ul>

                    <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <i class='search-btn bx bx-search'></i>
                            <i class='close-btn bx bx-x'></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="menu-icon d-in-line">
                                <span class="burger-menu menu-icon-two"><i class='flaticon-menu'></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Navbar Area
<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                    </ul>
                    <ul class="navbar-nav m-auto">
                    </ul>
                    <ul class="navbar-nav m-auto">
                  <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                                <li class="nav-item">
                                <a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nos projets</a></li>
<li class="nav-item">
                                <a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
</ul>
                        <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <i class='search-btn bx bx-search'></i>
                            <i class='close-btn bx bx-x'></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="menu-icon d-in-line">
                                <span class="burger-menu menu-icon-two"><i class='flaticon-menu'></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-header">
            <div class="sidebar-logo">
                <img src="assets/img/logo.png" alt="Image">
            </div>
            <span class="close-btn sidebar-modal-close-btn"><i class="bx bx-x"></i></span>
        </div>

        <div class="sidebar-about">
           <!-- <div class="title">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt ut labore et.</p>
            </div>-->
        </div>

        <div class="contact-us">
            <h2>Contactez nous</h2>

            <ul>
                <li>
                    <i class='bx bx-current-location'></i>
                    Adresse:Boulevard Ely Manel Fall BP 565. Diourbel - Sénégal

                </li>
                <li>
                    <i class='bx bx-mail-send'></i>
                    <a href="infos@sidio-group.com">infos@sidio-group.com</a>
                </li>
                <li>
                    <i class='bx bx-phone-call'></i>
                    <a href="tel:+221 33 971 52 92"> (+221) 33 971 52 92</a>
                </li>
            </ul>
        </div>

        <!--<div class="sidebar-instagram-feed">
            <h2>Instagram</h2>
            <ul>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram1.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram2.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram3.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram4.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram5.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram6.jpg" alt="image"></a>
                </li>
            </ul>
        </div> -->

        <div class="sidebar-follow-us">
            <h2>Follow Us</h2>

            <ul class="social-wrap">
               <!-- <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
            </ul>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->
