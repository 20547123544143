import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-details-agro',
  templateUrl: './projects-details-agro.component.html',
  styleUrls: ['./projects-details-agro.component.scss']
})
export class ProjectsDetailsAgroComponent implements OnInit {

  constructor(){
}

  ngOnInit(): void {

  }

}
