<!-- Inner Banner -->
<div class="inner-banner inner-bg14">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Projets</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Agro-alimentaire</li>d
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Details Area -->
<div class="project-details-area pt-100 pb-70">
    <div class="container">
        <div class="project-article">

            <div class="project-content">
                <h2>L'agro-alimentaire: Une priorité pour nous</h2>
                <p>Conscient des enjeux et du potentiel du secteur de l'agriculture, SIDIO s'engage sur des projets structurants. Nous travaillons à tirer le meilleur de nos terres notamment dans les filières céréalières, maraichères, horticoles. </p>
                <img src="assets/img/project/project-imgxx.jpg" alt="Images">
<h2>Filières céréalières, maraichères etc. </h2>
 </div>
            <div class="project-another-content">
                <div class="content-img">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-img8.jpg" alt="Images">
                        </div>
                        <div class="col-lg-6 col-md-6">
<p>Parmi nos produits phares: Le morenga ou l'arbre de vie.</p>
<p>La promotion de cette richesse de l'Afrique s'avère être une nécéssité. Sur un champ de plusieurs hectares,SIDIO entame une culture de plants de morenga destinés à la consommation, de la fabrication de produits phytotérapeuthiques</p>
                            <img src="assets/img/blog/blog-detailsx.jpg"  align="middle" alt="Images">

</div>
                    </div>
                </div>
<div class="project-content">
                <h2>Transformation alimentaire</h2>
               <h2>Le riz et l'huile: Deux denrées phares de notre région.</h2>
                <p>Parmi les produits agricoles les plus présent au Sénégal, nous pouvons retrouver le riz et l'arachide.  SIDIO fait le choix de s'investir dans les procédés de fabrication contrôlés qui permettent une transformation de ces matières premières agricoles en aliments que nous consommons quotidiennement.</p>
             <p>Suivant une connaissance appliquée et une rigueur dans le contrôle des matières premières et leur transformation, nous obtenons des produits de qualité ensuite placés et distribués à travers nos  propres canaux.</p>
            </div>

 c

            <div class="col-lg-4 col-md-12">
                <div class="solutions-btn">
                    <a routerLink="/contact" class="default-btn">Contactez-nous</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Solutions Area End -->

<!-- Project Area -->
<div class="project-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-title">
                    <div class="section-title">
                        <span>Nos Projets</span>
                        <h2>Nous travaillons aussi sur d'autres domaines</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-text">
                    <p>SIDIO réunit une dizaine d'entreprises qui proposent des services et produits de haute qualité dans cinq secteurs majeurs</p>
                </div>
            </div>
        </div>

        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Services</a></li>
                <li><a href="#">Production industrielle diverses</a></li>
                <li><a href="#">Immobilier</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-services"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-services">Services</a></h3>
                                       <div class="content">
                                          <p></p>
                                            <a routerLink="/projects-details-services" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-prod"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-prod">Production industrielles diverses</a></h3>
                                      <div class="content">
                                           <p></p>
                                            <a routerLink="/projects-details-prod" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details-immo"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-immo">Immobilier</a></h3>
                                       <div class="content">
                                            <p></p>
                                            <a routerLink="/projects-details-immo" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>SIDIO: un groupe qui s'active dans le monde depuis Diourbel</h2>
             </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->


<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Soumettre</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

