<!-- Inner Banner -->
<div class="inner-banner inner-bg12">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Santé et Bien-être</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Santé et Bien-être</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Details Area -->
<div class="project-details-area pt-100 pb-70">
    <div class="container">
        <div class="project-article">
<div class="project-content">
                <h2>La santé et le bien-être à base d'ingrédients naturels</h2>
            </div>

            <div class="project-another-content">
                <div class="content-img">
                    <div class="row">
            <div class="div-categoryContainer">
              <img src="assets/img/blog/blog-details11.jpg"  align=right >
               <div class="paragraph-left">
    <h2 >Produits pour cheveux</h2>
   <span> <p>Pour assurer la pousse de cheveux et une très bonne qualité de cheveux, nos partenaires compétents et certfiés dans le domaine proposent des  produits actifs, base de solutions de soins pour cheveux.</p> </span>
</div>
</div>
</div>

<div class="div-categoryContainer">
              <img src="assets/img/blog/blog-details2.jpg" class="picture-left">
               <div class="paragraph-right">
    <h2>Poudre,gélules et  thés à base de Morenga</h2>
   <span> <p>Tous les Moringas ne se valent pas : A l'issue d'un processus de production maitrisé par nos filiales, nous faisons un tri particulier afin de realiser des solutions de thés, gélules et poudre de qualités qui preservent les proprités et verus immense de cette plante magique.</p> </span>
</div>
</div>

<!--
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/blog/blog-details3.jpg" alt="Images"><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                        </div>
                    </div>-->

<div class="row">
            <div class="div-categoryContainer">
              <img src="assets/img/blog/blog-details10.jpg"  align=right >
               <div class="paragraph-left">
    <h2 >Fourniture de Produits cosmétiques</h2>
   <span> <p>Notre souhait est de proposer des crèmes de qualité hydratantes douce pour peaux sèches, visage, mains et pieds, corps adoucissant avec de la glycérine pour garder votre peau douce et votre teint naturel.
Face à l'afflux de produits de beauté importés en Afrique, nous voulons faire la différence et proposer des solutions prouvées.</p> </span>
</div>
</div>
</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Project Details Area End -->

<!-- Solutions Area -->
<div class="solutions-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="solutions-content">
                    <div class="section-title">
                        <span></span>
                        <h2>Découvrez nos filiales. </h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="solutions-btn">
                    <a routerLink="/contact" class="default-btn">Contactez-nous</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Solutions Area End -->

<!-- Project Area-->
<div class="project-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-title">
                    <div class="section-title">
                        <span>Nos Projets</span>
                        <h2>Nous travaillons aussi sur d'autres domaines</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-text">
                    <p>SIDIO réunit une dizaine d'entreprises qui proposent des services et produits de haute qualité dans cinq secteurs majeurs</p>
 </div>
            </div>
        </div>

        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Services</a></li>
                <li><a href="#">Production industrielle diverses</a></li>
                <li><a href="#">Immobilier</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-services"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-services">Services</a></h3>
                                       <div class="content">
                                          <p></p>
                                            <a routerLink="/projects-details-services" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-prod"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-prod">Production industrielles diverses</a></h3>
                                      <div class="content">
                                           <p></p>
                                            <a routerLink="/projects-details-prod" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
 <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details-immo"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-immo">Immobilier</a></h3>
                                       <div class="content">
                                            <p></p>
                                            <a routerLink="/projects-details-immo" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
</div>
        </div>
    </div>
</div>

<!-- Project Area End -->
<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>SIDIO: un groupe qui s'active dans le monde depuis Diourbel</h2>
             </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->
<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Soumettre</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->
