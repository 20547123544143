import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() {
  }
  ngOnInit(): void {

}/*
processFormData(any):any{
var form:HTMLElement  = document.getElementById('signup');
form.addEventListener('submit', (event) => {
    // handle the form data
    var nom:HTMLElement = document.getElementById("name").lue;
console.log(nom);
var prenom:HTMLElement = document.getElementById("firstname").value;
var email:HTMLElement = document.getElementById("email").value;

});
}
onsubmit(){
      const nodemailer = require('nodemailer');

const transporter = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: 'yaramdiop@gmail.com',
    pass: 'YaramDiop&2020' // naturally, replace both with your real credentials or an application-specific password
  }
});
  // les options à passer en parametre de sendmail
      const mailOptions:any = {
        from: 'yaramdiop@gmail.com', // email de l'envoyeur
        to:'yaramdiop@gmail.com',
                //`${this.client.email}`, // email du recepteur
        subject: 'Confirmation de la reservation', // Sujet de l'email
        // le corps de l'email, on peut y écrire du html et le style css
        // un example de texte avec html
        text: 'e une reservation'
      }
    }*/
}


