
<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">

    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Images"></a>
                    </div>
                  <!--  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore magna.</p> -->
                 <!--   <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>-->
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
              <!--  <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-1">Services</a></li>
                        <li><a routerLink="/projects">Projects</a></li>
                        <li><a routerLink="/case-study">Cases</a></li>
                        <li><a routerLink="/blog-1">Blog</a></li>
                    </ul>
                </div>-->
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                       <!-- <li>
                            <i class='bx bx-time'></i>
                            Lundin - Vendredi: 8h - 18h
                        </li>-->

                        <li>
                            <i class='bx bx-location-plus'></i>
                        Boulevard Ely Manel Fall BP 565. Diourbel - Sénégal
                        </li>
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+221 33 971 52 92">+221339715292</a>
                        </li>

<li>
     <i class='bx bx-mail-send'></i>
                    <a href="infos@sidio-group.com">infos@sidio-group.com</a>
</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <h3>Newsletter </h3>
                    <div class="newsletter-area"></div>
                        <form class="newsletter-form" method="post" id="signup" action="http://sidio-group.com">
                            <input type="text" class="form-control" placeholder="Entrez votre Nom*" name="NOM" id="name">
                            <br><input type="text" class="form-control" placeholder="Entrez votre Prénom*" name="PRENOM" id ="firstname">
                            <br><input type="email" class="form-control" placeholder="Entrez votre email*" name="EMAIL" id="email">
                            <button class="default-btn" type="submit" onclick="onsubmit();" >Abonnez-vous </button>
                        </form>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>Copyright @2021 Yaqin All Rights Reserved</p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->
