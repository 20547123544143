<!-- Inner Banner -->
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Galerie</h3>
           <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Blog</li>
            </ul>-->
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Blog Area Two -->
<div class="blog-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-card">
                            <a routerLink="/blog-details"><img src="assets/img/blog/blog-img1.jpg" alt="Images"></a>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Visite d'une usine de fabrication de tracteurs</a></h3>
                               <!-- <ul>
                                    <li>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                    <li><span>20</span> COMMENTS /</li>
                                    <li><span>28</span> VIEWS</li>
                                </ul>-->
                                <p>Nous souhaitons nous inspirer des meilleurs pour développer des modèles de tracteurs adaptés à notre marché </p>
                              <!--  <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>-->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="blog-card">
                            <a routerLink="/blog-details"><img src="assets/img/blog/blog-img7.jpg" alt="Images"></a>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Visite de SOLIDO</a></h3>
                              <!--  <ul>
                                    <l
                                    i>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                    <li><span>24</span> COMMENTS /</li>
                                    <li><span>20</span> VIEWS</li>
                                </ul>-->
                                <p>Partenaire de SIDIO, la société SOLIDO est une usine de fabrication de pneus qui distribue ses produits partout dans le monde. </p>
                               <!-- <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>-->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="blog-card">
                            <a routerLink="/blog-details"><img src="assets/img/blog/blog-img3.jpg" alt="Images"></a>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Visite du groupe SIDIO</a></h3>
                                <!--<ul>
                                    <li>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                    <li><span>20</span> COMMENTS /</li>
                                    <li><span>14</span> VIEWS</li>
                                </ul> -->
                                <p>Visite des entreprises du groupe SIDIO par les autorités locales,  lors du conseil régional de dévéloppement qui s'est tenu à Diourbel </p>
                            <!--   <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>-->
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="blog-card">
                            <a routerLink="/blog-details"><img src="assets/img/blog/blog-img4.jpg" alt="Images"></a>
                            <div class="content">
                                <h3><a>Visite de SOLIDO</a></h3>
                              <!--  <ul>
                                    <li>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                    <li><span>24</span> COMMENTS /</li>
                                    <li><span>30</span> VIEWS</li>
                                </ul>-->
                                <p>Partenaire de SIDIO, la société SOLIDO est une usine de fabrication de pneus qui distribue ses produits partout dans le monde. </p>
                               <!-- <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>-->
                            </div>
                        </div>
                    </div>

                    <!--<div class="col-lg-6 col-md-6">
                        <div class="blog-card">
                            <a routerLink="/blog-details"><img src="assets/img/blog/blog-img5.jpg" alt="Images"></a>
                            <div class="content">
                               <h3><a>Captures de la visite de Siftamec lors du forum internation sur l'utilisation de l'eau au 25 Mars</a></h3>-->
                               <!-- <ul>
                                    <li>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                    <li><span>10</span> COMMENTS /</li>
                                    <li><span>20</span> VIEWS</li>
                                </ul>
                             <p>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit. </p>
                                <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                            </div>
                        </div>
                    </div>-->


                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="side-bar-wrap">
                    <div class="search-widget">
                        <form class="search-form">
                            <input type="search" class="form-control" placeholder="Search...">
                            <button type="submit"><i class="bx bx-search"></i></button>
                        </form>
                    </div>

                   <!-- <div class="side-bar-widget">
                        <h3 class="title">Categories</h3>

                        <div class="side-bar-categories">
                            <ul>
                                <li>
                                    <a routerLink="/blog-1">Business Planning </a>
                                    <span>(1)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Project Managment </a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Human Resources </a>
                                    <span>(3)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Business Consulting </a>
                                    <span>(4)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Project</a>
                                    <span>(5)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Strategy</a>
                                    <span>(6)</span>
                                </li>
                            </ul>
                        </div>
                    </div>-->

                    <!--<div class="side-bar-widget">
                        <h3 class="title">Recent Posts</h3>

                        <div class="widget-popular-post">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg1" role="img"></span></a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Success Depends on Strategy and Plan</a></h4>
                                    <p>March 20, 2020</p>
                                </div>
                            </article>
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg2" role="img"></span></a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Reaching the Goal With Best Consulting</a></h4>
                                    <p>June 11, 2020</p>
                                </div>
                            </article>
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg3" role="img"></span></a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Aim is to Reach My Goal With Best Plan</a> </h4>
                                    <p>August 10, 2020</p>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Tags</h3>

                        <ul class="side-bar-widget-tag">
                            <li><a routerLink="/blog-1">Business</a></li>
                            <li><a routerLink="/blog-1">Planning</a></li>
                            <li><a routerLink="/blog-1">Project</a></li>
                            <li><a routerLink="/blog-1">Managment</a></li>
                            <li><a routerLink="/blog-1">Strategy</a></li>
                            <li><a routerLink="/blog-1">Resources</a></li>
                        </ul>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog Area Two End -->
