<!-- Inner Banner -->
<div class="inner-banner inner-bg15">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Nos Projets</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Machinerie</li>d
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Details Area -->

<div class="project-details-area pt-100 pb-70">

    <div class="container">
<div class="project-another-content">
<div class="project-content">
                         <div class="col-lg-6 col-md-6">
                <h2>Fourniture de machineries<br><br></h2>
</div>
</div>
                <div class="content-img">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach2.jpg" alt="Images">
                        </div>

                         <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach1.jpg" alt="Images">
                        </div>
                    </div>
                </div>
        <div class="project-article">
           <!-- <div class="blog-article-img">
                <img src="assets/img/project/project-details1.jpg" alt="Images">
            </div>-->

            <div class="project-content">
                <p>Nous fournissons des équipements lourds de toute sorte  et de la machinerie de qualité provenant de différents pays. Faites votre choix parmi notre large gamme de machinerie pouvant combler tous vos besoins. </p>
            </div>
                <p></p>
<div class="project-another-content">
                <div class="content-img">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                        </div>
                    </div>
                </div>

<div class="project-another-content">
                <div class="content-img">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach33.jpg" alt="Images">
                            <img src="assets/img/project/project-imgmach44.jpg" alt="Images">
                        </div>

                         <div class="col-lg-6 col-md-6">
                <h2>Gros plan sur cinq familles de machines</h2>
                <ul class="social-icon">
                    <li>Matériel BTP</li>
                    <li>Revêtement Sol&Mur/ Sanitaires/Plomberie</li>
                    <li>Ameublement</li>
                    <li>Camions</li>
                    <li>Matériel de construction</li>
                </ul>
                        </div>
                    </div>
                </div>
</div>
                      <h2>Accessible partout en Afrique</h2>
                    <p>Nos produits sont adapté au marché africain et s'adressent au public et aux entrepreneurs.
Nos produits sont fabriquées par des marques reconnues.</p>

<div class="project-another-content">
                <!--<div class="content-img">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach3.jpg" alt="Images">
                        </div>

                         <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach5.jpg" alt="Images">
                        </div>
                    </div>-->
                </div>
</div>

<div class="project-another-content">
                <div class="content-img">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach77.jpg" alt="Images">
<div class="solutions-btn">
                    <a href="https://siftamec.com" class="default-btn">Découvrez notre filiale</a>
                </div>
                        </div>

                         <div class="col-lg-6 col-md-6">
                            <img src="assets/img/project/project-imgmach6.jpg" alt="Images">
                            <img src="assets/img/project/project-imgmach66.jpg" alt="Images">

                        </div>
                    </div>
                </div>
</div>
        </div>
    </div>
</div>
<!-- Project Details Area End -->

<!-- Solutions Area-->
<div class="solutions-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="solutions-content">
                    <div class="section-title">
                        <span> </span>
                        <h2>Découvrez nos filiales dans ces domaines</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="solutions-btn">
                    <a routerLink="/contact" class="default-btn">Contactez-nous</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Solutions Area End -->

<!-- Project Area-->
<div class="project-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-title">
                    <div class="section-title">
                        <span>Nos Projets</span>
                        <h2>Nous travaillons aussi sur d'autres domaines</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-text">
                    <p>SIDIO réunit une dizaine d'entreprises qui proposent des services et produits de haute qualité dans cinq secteurs majeurs</p>
                </div>
            </div>
        </div>

        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Services</a></li>
                <li><a href="#">Production industrielle diverses</a></li>
                <li><a href="#">Immobilier</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
 <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-services"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-services">Services</a></h3>
                                       <div class="content">
                                          <p></p>
                                            <a routerLink="/projects-details-services" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details-prod"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-prod">Production industrielles diverses</a></h3>
                                      <div class="content">
                                           <p></p>
                                            <a routerLink="/projects-details-prod" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
</div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details-immo"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details-immo">Immobilier</a></h3>
                                       <div class="content">
                                            <p></p>
                                            <a routerLink="/projects-details-immo" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>

               <!-- <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Strategy</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Human Resources</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Strategy</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a href="project-details.html">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="projects-details.html"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>SIDIO: un groupe qui s'active dans le monde depuis Diourbel</h2>
             </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->
<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Soumettre</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->
